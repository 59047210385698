import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">  
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side mb-0 mb-md-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor'>NFT Minting </span>Platform Development</h1>
                <p className='pharagraph'>Get premium-level NFT Minting Platform development services within your budget from Coinsclone. Coinsclone’s NFT developers are experts in leveraging your NFT business from zero to hero in no time.</p>
                <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Talk to our Experts</a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/nft-minting/nft-minting-platform-development.webp"
                alt="NFT Minting Platform Development"
                className='d-none d-md-block float-end'
                width={661}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection