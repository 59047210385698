import React from 'react';
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import BannerCrypto from '../components/nft-mint/Banner';
import FaqSection from '../components/nft-mint/FaqSection';
import TechStack from '../components/nft-mint/TechStack';
import UserFriendly from '../components/Cryptoexchange/UserFriendly';
import WeProvide from '../components/nft-mint/WeProvide';
import HowtoGet from '../components/nft-mint/HowtoGet';
import BenefitsOf from '../components/nft-mint/BenefitsOf';
import DevProcess from '../components/nft-mint/DevProcess';
import WhyChoose from '../components/nft-mint/WhyChoose';
import Whatis from '../components/nft-mint/Whatis';
import AddOn from '../components/nft-mint/AddOn';
import WeCreate from '../components/nft-mint/WeCreate';

const IcoConsult = () => (
  <section className="cryptoex">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/nft-minting-platform-development/" />
        <title>NFT Minting Platform Development Company - Coinsclone</title>
        <meta name="description" content="Coinsclone is a prominent NFT Minting Platform development company that allows you to create a website that benefits users with minting, and auctioning NFTs." />
        <meta name="keywords" content="NFT Minting Platform Development" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="NFT Minting Platform Development Company - Coinsclone" />
        <meta property="og:description" content="Coinsclone is a prominent NFT Minting Platform development company that allows you to create a website that benefits users with minting, and auctioning NFTs." />
        <meta property="og:url" content="https://www.coinsclone.com/nft-minting-platform-development/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/nft-minting/nft-minting-platform-development.webp" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone is a prominent NFT Minting Platform development company that allows you to create a website that benefits users with minting, and auctioning NFTs." />
        <meta name="twitter:title" content="NFT Minting Platform Development Company - Coinsclone" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/nft-minting/nft-minting-platform-development.webp" />
      </Helmet>
      
      <BannerCrypto />
      <div className="breadcrumb wallet-banner mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | NFT Minting Platform Development</span>
        </div>
      </div>
      <Whatis />
      <WeProvide />
      <HowtoGet />
      <DevProcess />
      <BenefitsOf />
      <AddOn />
      <WeCreate />
      <WhyChoose />
      <TechStack />
      <FaqSection />
      <UserFriendly /> 
    </Layout>
  </section>
)

export default IcoConsult
