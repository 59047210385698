import React from "react"

class BenifitsOf extends React.Component {

  render() {
    return (
      <section className="dapps consult pt-100 mb-0">
        <div className="gray-bg">
        <div className="container">
            <h2 className="heading-h2 text-center"><span className="bluecolor">Features we </span>offer in our NFT Minting Platform</h2>
            <p className='text-center'>We pack your NFT Minting Platform with loaded security features, trading features, and user-friendly navigation on the admin/user panel.</p>
          <div className="row">
                    <div className='d-flex flex-wrap justify-content-center px-0'>
                        <div className='fea-card'>
                            <h3>Refined Search</h3>
                            <p className='pharagraph'>Our NFT Minting Platform offers high-end search options for users to find out the NFTs and their collectibles easily. Along with this, we also include the search history option for users for further searching.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Multi-chain Support</h3>
                            <p className='pharagraph'>We integrate the NFT Minting Platform with various blockchain network compatibility options. This paves the way for multiple user traction and worldwide usage on the minting platform.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>MetaData Management</h3>
                            <p className='pharagraph'>Even after creating the NFTs, the creators can customize their metadata, description, NFT design, and many more with this feature. Crypto users can update them as per their specifications and needs.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Automation Tools</h3>
                            <p className='pharagraph'>We help you to include automation tools for NFT minting, listing, and various other purposes. This reduces the manual effort for NFT creation and streamlines for creation of a unique non-fungible token.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Support System</h3>
                            <p className='pharagraph'>Our development team provides an in-build customer care and support system on the NFT Minting Platform. Through this, users can raise tickets regarding minting issues, minting fees,  etc at any time. </p>
                        </div>
                        <div className='fea-card'>
                            <h3>Wallet Integration</h3> 
                            <p className='pharagraph'>Users can integrate their wallet for the NFT Minting process in a flexible way. Our team designs an NFT Minting Platform that even supports cold wallet storage to secure non-fungible tokens offline.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Minting Options</h3> 
                            <p className='pharagraph'>Our NFT Minting Platform possesses various minting options including lazy minting and batch minting. This helps you to gather the attention of various sets of users who want to create NFTs in bulk.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Custom Store-Front</h3> 
                            <p className='pharagraph'>Our customizable storefront includes all the necessary data regarding bids, auctions, price history, and new NFT launches. This increases the tractions of the minting platform and is useful for crypto users. </p>
                        </div>
                        <div className='fea-card'>
                            <h3>Royalty Management</h3> 
                            <p className='pharagraph'>The NFT Minting Platform we develop includes royalty management where the NFT creators can get their payments during the secondary sales. Users can also modify or customize their royalty payment as per their specifications.</p>
                        </div>
                    </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default BenifitsOf
