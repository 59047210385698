import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">
      <Container>
        <h2 className="heading-h2 text-center">Frequently Asked Questions</h2>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                What is an NFT Minting Platform?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">The NFT Minting Platform allows creators to mint unique non-fungible tokens on the various blockchain for different purposes like buying, selling and trading. Digital collectibles like art, real estate, music albums, etc can all be converted into tokens and minted on this platform.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                Is an NFT Minting website a profitable business?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">Yes. Creating an NFT Minting website helps newbie startups earn enough profits. Startups can generate revenue through minting, listing, subscription, commission, advertisements, and collaborations.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                How long does it take to build an NFT Minting Platform?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">It takes nearly 15 days to 6 months. Yet, this is not the exact timeline for creating the NFT Minting Platform. Depending on various factors like development company, project complexity, feature integration, etc the duration may change.
                </div>
              </Accordion.Collapse>
            </div> 
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                How much does it cost to create a NFT Minting Platform?
                </Accordion.Toggle>
              </div>  

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">The approximate cost to create an NFT Minting Platform would range between $20,000 to $45,000. The above-mentioned cost may vary based on the factors like feature designing, additional add-on modules, etc.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle"  eventKey="4">
                Which company offers the best NFT Minting Platform  Development services?
                </Accordion.Toggle>
              </div>  

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">Coinsclone is a prominent NFT Minting Platform development company in the crypto market. They possess a skilled set of developers, and technicians to complete your NFT Minting Platform creation process at an affordable cost. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '5' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle"  eventKey="5">
                What are some successful NFT Minting Platforms in the market?
                </Accordion.Toggle>
              </div>  

              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">Some of the successful NFT Minting Platforms in the market are Opensea, Rarible, Foundation, Superrare, etc. These platforms offer various other NFT-related services also.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion> 
      </Container>
    </section>
  )
}

export default FaqSection