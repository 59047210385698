import React from 'react'


const WeCreate = () => {


  return (
    <section className="customs mint pt-100 mb-0">
        <div className="container">
            <div className="row">
                <h2 className="heading-h2 text-center"><span className="bluecolor">Why Create</span> an NFT Minting Platform?
                </h2>
                <p className='text-center'>When it comes to creating an NFT Minting Platform, settling for less is not in our plans at any moment. So, seize our NFT Minting Platform development perks.</p>
                <div className='d-flex flex-wrap justify-content-center top'>
                    <div className='custom-blk'>
                        <h3>Highly Profitable</h3>
                        <p class="pharagraph">Our NFT Minting Platform offers startups huge profits with a limited investment. We ensure that our platform provides stable income streams like bidding fees, listing fees, minting fees, subscription fees, etc.</p>
                    </div>
                    <div className='custom-blk'>
                        <h3>NFT Market Outreach</h3>
                        <p class="pharagraph">As the NFT market is volatile, users expect new innovative features for minting their NFTs. We assure you that our NFT Minting Platform creation will help you to create a brand identity in the NFT market.</p>
                    </div>
                    <div className='custom-blk'> 
                        <h3>Earn tractions</h3>
                        <p class="pharagraph">NFT users who want to convert their assets to NFTs soon and effortlessly will approach the NFT Minting Platform. This paves the way for higher audience views and tractions for the platform.</p>
                    </div>
                    <div className='custom-blk'>
                        <h3>Sustainability</h3>
                        <p class="pharagraph">NFT Minting Platform development is a sustainable business idea for startups who want success without any hassles. Our NFT Minting Platform will act as the strongest foundation for your upcoming businesses.</p>
                    </div>
                    <div className='custom-blk'>
                        <h3>Highly-Secure</h3>
                        <p class="pharagraph">As the NFT Minting Platform is essential and the first step for NFT trading, we create the platform with the highest security features. We integrate features that protect the NFT Minting website from hackers and scammers.</p>
                    </div>
                    <div className='custom-blk'>
                        <h3>Community Building</h3>
                        <p class="pharagraph">With the enhanced NFT Minting Platform, you can form a community that comprises NFT users. This directs your business to the next level with potential users and it also creates brand recognition.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default WeCreate