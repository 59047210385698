import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis pt-0">
        <div className='gray-bg'>
        <div className="container">
                <h2 className="heading-h2 text-center"><span className='bluecolor'>NFT Minting </span>Platform Development Company - Coinsclone </h2>
            <div className="text-center">
              <p className="pharagraph text-center">Coinsclone’s NFT Minting Platform development services allow the creation of a minting platform with potential features and security mechanisms for NFT enthusiasts. Our team strives to offer numerous NFT Minting Platform creation solutions that ensure you stand ahead of the curve. Sail your NFT business boat with our team without any secondary thoughts.
              </p>
              <p className="pharagraph text-center mb-0">Our development team is blended with enriched website development and mobile app creation skills. We have enough experience in creating NFT Minting websites that offer exponential trading features like batch minting, lazy minting, etc. We offer our NFT Minting Platform development services on various blockchain networks including Ethereum, Solana, Polygon, etc. 
              </p>
            </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis