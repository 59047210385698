import React from "react"

class HowtoGet extends React.Component {

  render() {
    return (

      <section className="icon whitelabel-use mint pt-100 secureof">
        <div className="gray-bg">
          <div className="container">
            <h2 className="heading-h2 text-center"><span className='bluecolor'>Token Standards</span> Of Our NFT Minting Website</h2>
            <p className='text-center mw1030'>Get our NFT Minting Platform development services that support multiple token standards of various blockchain networks.</p>
            <div className="row ">
              <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
                <div className='leftdiv'>
                  <h3 className='head3'>
                  ERC721
                  </h3>
                  <p className="pharagraph">Popular Blockchain network Ethereum supports the creation of non-fungible tokens with the ERC721 token standard. Users can create NFTs on this token standard for various purposes including gaming, virtual assets, etc. We ensure to offer seamless integration, high security, and unmatched ERC721 token standards for your website.</p>
                </div>
                <div className='leftdiv'>
                  <h3 className='head3'>
                  ERC1155
                  </h3>
                  <p className="pharagraph">Our NFT Minting Platform supports users to mint non-fungible tokens on the ERC1155 token standard. We offer a minting platform that provides highly efficient, flexible, and ideal ERC1155 crypto tokens to mint. Our development team utilizes advanced technology that makes you leverage your NFT business.</p>
                </div>
                <div className='leftdiv'>
                  <h3 className='head3'>
                  TRC721
                  </h3>
                  <p className="pharagraph mb-lg-0">Our NFT Minting website helps crypto users mint their tokens on the Tron blockchain with the TRC721 token standard. The platform’s TRC721 tokens facilitate interoperability and highly secure transactions. With higher quality, our team offers the best-of-all TRC721 token standard services for your NFT Minting Platform.</p>
                </div>
                <div className='leftdiv'>
                  <h3 className='head3'>
                  BEP721
                  </h3>
                  <p className="pharagraph mb-lg-0">The BEP721 is a Binance blockchain token standard that empowers users to convert their assets into NFTs. Users worldwide can create their NFTs from our spectacular NFT Minting Platform. We design a minting platform that supports the BEP721 token standard for users who like to create their NFTs in the BNB chain.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    )
  }
}

export default HowtoGet
