import React from "react"

class DevProcess extends React.Component {

  render() {
    return (

      <section className="fine-box mint pt-100 mb-0">
          <div className="container"> 
            <h2 className="heading-h2 text-center">NFT Minting Platform <span className="bluecolor">Development Process </span></h2>
            <p className="text-center mb-2">
            Delve into our insightful development process in which state-of-the-art technology meets unparalleled creativity.
            </p>
            <div className="d-lg-flex flex-wrap add-one px-0">
              <div className="square" >
                <h3>Conceptualization</h3>
                <p className="pharagraph">
                First, we will start by evaluating your NFT project idea and define the development procedures, feature implementation, legal aspects, etc. We help you to frame your ideas that match the present NFT user’s expectations and needs.
                </p>
              </div>
              <div className="square" >
                <h3>Smart Contract Architecture</h3>
                <p className="pharagraph">
                To create a decentralized NFT Minting Platform, our smart contract developers create valuable smart contracts that fit your website. We include essential features on the smart contract for hassle-free NFT minting.
                </p>
              </div>
              <div className="square" >
                <h3>Designing</h3>
                <p className="pharagraph">
                In order to satisfy the user needs for the NFT Minting Platform, our UI/UX designers will configure the website accordingly. We also design the platform that will be best for mobile apps and desktop views.
                </p>
              </div>
              <div className="square" >
                <h3>Development</h3>
                <p className="pharagraph">
                Then comes the role of our development team where they input the codes on both the back-end and front-end. Our team codes for all the basic and advanced-level features of the NFT Minting Platform.
                </p>
              </div>
              <div className="square" >
                <h3>Quality Assurance</h3>
                <p className="pharagraph">
                After the completion of the development process, our testers will ensure the quality of the NFT Minting Platform. We also find out the technical errors, bugs, and coding mistakes to avoid malfunctions on the website.
                </p>
              </div>
              <div className="square" >
                <h3>Deployment</h3>
                <p className="pharagraph">
                Deploying the NFT Minting Platform for users will be the final stage of the website development process. Our expert team helps you in sorting out issues which might occur once in a while. We also offer pro-longed support and regular upgrades.
                </p>
              </div>
            </div>
          </div>
      </section>
    )
  } 
}

export default DevProcess
