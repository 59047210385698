import React from "react"



class WeProvide extends React.Component {

  render() {
    return (
      <div className="pt-100">
      <section className="usr-admin-tab benifor mint mb-0">
          <div className="container"> 
            <h2 className="heading-h2 text-center"><span className="heading-h3">Coinsclone offers the Best NFT</span> Minting Platform <span className="bluecolor">Development Services</span></h2>
            <p className="text-center">
            Make your NFT Minting Platform thrive in the crypto industry with our creation services. We pave the way for success with numerous development services.
            </p>
            <div className="row">
                  <div className="d-lg-flex flex-wrap add-one px-0">
                    <div className="square" >
                      <h3>Minting Platform Development</h3>
                      <p className="pharagraph">
                      Our development team is an expert in developing a website that helps NFT users convert their art, music albums, or anything into non-fungible tokens. We design the platform with various features for hassle-free NFT creation.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Consultation Services</h3>
                      <p className="pharagraph">
                      We initiate the NFT Minting website creation with valuable insights, guidance, and strategies. We possess a pool of skilled experts to guide you in creating the NFT Minting Platform with high revenue streams, and legal and regulatory aspects.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Smart Contract Development Services</h3>
                      <p className="pharagraph">
                      Our smart contract architects offer you potential smart contracts that are more transparent and help the platform’s users to mint, buy, and sell NFT collectibles. We also audit the smart contracts once in a while to ensure the working nature.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>NFT Minting App Development</h3>
                      <p className="pharagraph">
                      We possess mobile app developers who thoroughly analyze the mobile app user needs to create an NFT Minting app. The NFT Minting Platform encourages crypto users to mint their NFTs at any time and anywhere.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>NFT Token Creation</h3>
                      <p className="pharagraph">
                      Our development team comprises a whirlwind of knowledge which makes them create potential NFTs. We design the asset and configure the metadata which offers unique ownership opportunities for the creators. 
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Smart Contract Auditing</h3>
                      <p className="pharagraph">
                      We are experts in creating smart contracts and also well-versed in auditing and rectifying smart contracts. Occasionally, we check out the smart contract’s functions and the work to assure quality.
                      </p>
                    </div>
                  </div>
            </div>
          </div>
      </section>
      </div>
    )
  }
}

export default WeProvide
