import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="bluecolor">What makes Coinsclone </span>the Top NFT Minting Platform Development Company?</h2>
              
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/nft-minting/what-make-coinsclone.webp"
                alt="NFT Minting Platform"
                placeholder='none'
                className='mt-3 mb-3'
                width={536}
              />
            </div>
            <div className="col-lg-6 col-md-12">
            <p className="pharagraph">As a pioneer in the NFT market, Coinsclone stands ahead with its top-notch NFT Minting Platform development services. We have successfully launched 100+ crypto projects that mostly include NFT-related platforms. Our development team is ready to create your NFT Minting website with world-class perfection at a reasonable price estimation.</p>
            <p className="pharagraph">Apart from our professionalism, our NFT minting platform development can bring you diverse business revenue streams. We have a craze for getting the best out of anything and your minting platform is no different. The NFT Minting feature has high demand among the users and by building your platform with us, you are in line to reach greater heights. Partner with our blockchain experts to get a clear idea of diverse development services.</p>
            <p className="pharagraph">Mint your success with our NFT Minting development services!</p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose